
import { Vue, Component, Watch } from "vue-property-decorator";
import { MovimentacaoService} from "@/core/services/almoxarifado";
import { Movimentacao, Transferencia } from "@/core/models/almoxarifado";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";

@Component
export default class ListaMovimentacao extends mixins(Vue, ListPage) {

  movimentacao = new Movimentacao();
  item = new Movimentacao();
  itemTransferencia = new Transferencia();
  service = new MovimentacaoService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogTranferencia: boolean = false;
  totalLista: number = 0;

  titulo: string = "Movimentação";
  subTitulo: string = "Movimentações cadastrados no Sistema";
  
  options: any = {
    itemsPerPage: 15,
  };
  
  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Movimentação', disabled: true, href: '/financeiro/painel/despesas'}
  ]
  
   headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Data", value: 'dataHora'},
    { text: "Produto", value: 'almoxarifadoItem.produto.nome'},
    { text: "Descrição", value: 'descricao'},
    { text: "Tipo", value: 'tipo.nome'},
    { text: "Origem", value: 'tipoOrigem.nome'},
    { text: "Movimentado", value: 'quantidade',},
  ];
  
  @Watch("options", { deep: true })
    Atualizar() {
      const { page, itemsPerPage, sortBy, sortDesc,columns } = this.options;
      this.loading = true;

      this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, columns,'','', 'AlmoxarifadoItem.Produto, TipoOrigem, Tipo').then(
        res => {
          this.lista = res.data.items;
          this.totalLista = res.data.count;
        },
      (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
      .finally(() => (this.loading = false));
    }
    
  Transferencia() {
    this.dialogTranferencia = true;
    this.movimentacao = new Movimentacao();
  }

  Novo() {
    this.item = new Movimentacao();
    this.dialogCadastro = true;
  }

  mounted(){
    //this.Atualizar();
  }
}
